.email-templates {
  &_list {
    max-height: 500px;
    overflow-y: scroll;
    &_item {
      cursor: pointer;
      &:hover, &__selected {
        background-color: #ced8e1; } } }
  &_form {
    button {
      margin: 0 5px; } } }
